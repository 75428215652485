@import '~@justgiving/carepack-design-tokens/dist/layout.scss';

$spacing-units: 0, $size-spacing-01, $size-spacing-02, $size-spacing-03,
  $size-spacing-04, $size-spacing-05, $size-spacing-06, $size-spacing-07,
  $size-spacing-08, $size-spacing-09, $size-spacing-10, $size-spacing-11,
  $size-spacing-12;

$directions: (
  't': 'top',
  'r': 'right',
  'b': 'bottom',
  'l': 'left',
);

@for $i from 1 through length($spacing-units) {
  $unit: nth($spacing-units, $i);
  $index: $i - 1;

  @each $prefix, $direction in $directions {
    .m#{$prefix}-#{$index} {
      margin-#{$direction}: $unit;
    }

    .my-#{$index} {
      margin-top: $unit;
      margin-bottom: $unit;
    }

    .mx-#{$index} {
      margin-left: $unit;
      margin-right: $unit;
    }

    .m-#{$index} {
      margin: $unit;
    }

    .p#{$prefix}-#{$index} {
      padding-#{$direction}: $unit;
    }

    .py-#{$index} {
      padding-top: $unit;
      padding-bottom: $unit;
    }

    .px-#{$index} {
      padding-left: $unit;
      padding-right: $unit;
    }

    .p-#{$index} {
      padding: $unit;
    }
  }
}

$section-spacing: 20px;

.py-section {
  padding-top: $section-spacing;
  padding-bottom: $section-spacing;
}

.px-section {
  padding-left: $section-spacing;
  padding-right: $section-spacing;
}

.p-section {
  padding: $section-spacing;
}

.mb-section {
  margin-bottom: $section-spacing;
}
