@import '../styles/spacing';
@import '../styles/colours';
@import '../styles/sizing';
@import '../styles/position';
@import '../styles/typography';
@import '../styles/breakpoints';

:root {
  --container-width: 1121px;
  --main-section-width: 676px;
  --outside-gutters: 18px;
  --avatar-column-size: 120px;
}

html {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-weight: var(--font-weight-regular);
  color: var(--color-text-dark);
}

.btn-unstyled {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
}

.list-unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}

.text-center {
  text-align: center;
}

// TODO: Move this class to carepack if AB test consumerfintech_jgcheckout_conversion_modal_to_redirect_f42 is successful
.cp-sr-only-always {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.hidden {
  display: none;
}

.lg-hidden {
  @include device-large {
    display: none;
  }
}

.lg-block {
  @include device-large {
    display: block;
  }
}

.underline {
  text-decoration: underline;
}
