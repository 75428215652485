.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.w-auto {
  width: auto;
}
