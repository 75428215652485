// Using carepack breakpoints defined in: https://zeroheight.com/70590cf4a/p/59ad0f-layout/b/56984a
@mixin device-small {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin device-medium {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin device-large {
  @media screen and (min-width: 1200px) {
    @content;
  }
}
